<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #content>
          <DataTable
            class="p-datatable-sm"
            :paginator="true"
            :rows="5"
            stripedRows
            :loading="loading"
            :value="propostas"
            dataKey="id"
            :filters.sync="filtros"
            :globalFilterFields="[
              'mesReferencia',
              'anoReferencia',
              'contrato',
              'tipo',
              'statusProposta.nome',
            ]"
            filterDisplay="menu"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25]"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} Propostas"
            responsiveLayout="stack">
            <template #header>
              <div class="flex flex-column sm:flex-row">
                <span class="p-input-icon-left mb-2 mr-2">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="filtros['global'].value"
                    placeholder="Pesquisar"
                    style="width: 100%" />
                </span>
                <Button
                  type="button"
                  icon="pi pi-filter-slash"
                  label="Limpar"
                  class="p-button-outlined mb-2"
                  @click="limparFiltro" />
              </div>
            </template>
            <template #empty> Nenhuma proposta encontrada. </template>
            <template #loading> Carregando. Por favor aguarde. </template>
            <Column field="mesReferencia" header="Mês/Ano" class="col-4">
              <template #body="slotProps">
                {{ ('0' + slotProps.data.mesReferencia).slice(-2) }}/{{
                  slotProps.data.anoReferencia
                }}
              </template>
            </Column>
            <Column :sortable="true" field="tipo" header="Tipo">
              <template #body="{ data }">
                {{ tipoProposta(data.tipo) }}
              </template>
            </Column>
            <Column header="Solicitação">
              <template #body="{ data }">
                {{ data.on_line ? 'Online' : 'Presencial' }}
              </template>
            </Column>
            <Column
              v-if="modulo === 'servidor'"
              field="rubrica.consignataria.razaoSocial"
              header="Consignataria"></Column>
            <Column field="contrato" header="Contrato"></Column>
            <Column field="prazoTotal" header="Prazo"></Column>
            <Column header="Valor da Parcela">
              <template #body="{ data }">
                <div class="flex justify-content-end flex-wrap card-container">
                  <div class="flex align-items-center justify-content-center">
                    {{ data.valorParcela | formatarValor }}
                  </div>
                </div>
              </template>
            </Column>
            <Column
              :sortable="true"
              field="statusProposta.nome"
              header="Situação">
              <template #body="{ data }">
                <Chip
                  v-if="data.statusProposta.nome === 'Pendente'"
                  label="Pendente"
                  class="mr-2 mb-2 custom-chip pendente" />
                <Chip
                  v-if="data.statusProposta.nome === 'Aprovada'"
                  label="Aprovada"
                  class="mr-2 mb-2 custom-chip ativa" />
                <Chip
                  v-if="data.statusProposta.nome === 'Cancelada'"
                  label="Cancelada"
                  class="mr-2 mb-2 custom-chip cancelada" />
                <Chip
                  v-if="data.statusProposta.nome === 'Reprovada'"
                  label="Reprovada"
                  class="mr-2 mb-2 custom-chip suspensa" />
              </template>
            </Column>
            <Column id="acoes" header="Ações">
              <template #body="slotProps">
                <Button
                  v-tooltip.left="'Detalhes'"
                  icon="pi pi-eye"
                  class="p-button-rounded p-button-success"
                  @click="exibirDetalheProposta(slotProps.data)" />
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import PropostaService from '@/service/PropostaService.js'
import { servidorStore } from '@/stores/servidor'

export default {
  setup() {
    const store = servidorStore()
    return { store }
  },

  data() {
    return {
      propostas: [],
      proposta: {},
      loading: false,
      idMatricula: this.$route.params.id,
      filtros: {},
      modulo: '',
    }
  },

  created() {
    this.propostaService = new PropostaService(this.$http)
    this.initFiltros()
  },

  mounted() {
    this.loading = true
    this.carregarProposta()
  },

  methods: {
    limparFiltro() {
      this.initFiltros()
    },

    initFiltros() {
      this.filtros = {
        global: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.CONTAINS },
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
      }
    },

    getPropostas() {
      this.propostaService
        .getListaPropostasConsignacaoDoServidor(this.idMatricula)
        .then((res) => {
          this.propostas = res
        })
        .catch((err) => {
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    carregarProposta() {
      this.loading = true

      if (
        this.$auth.hasRoleGestao() ||
        this.$auth.hasRoleGestaoAdmin() ||
        this.$auth.hasRoleServidor()
      ) {
        this.getPropostas()
      }

      if (
        this.$auth.hasRoleConsignatariaUser() ||
        this.$auth.hasRoleConsignatariaAdmin() ||
        this.$auth.hasRoleAverbar()
      ) {
        this.propostaService
          .getListaPropostasConsignatariaLogada(this.idMatricula)
          .then((res) => {
            this.propostas = res
          })
          .catch((err) => {
            this.$toast.add({
              severity: 'error',
              summary: err.response.data.message,
              life: 10000,
            })
          })
      }

      this.loading = false
    },

    tipoProposta(value) {
      if (value === 'NOVA_AVERBACAO') {
        return 'Nova Averbação'
      } else if (value === 'COMPRA_DE_DIVIDA') {
        return 'Portabilidade'
      } else if (value === 'RENEGOCIACAO') {
        return 'Renegociação'
      } else if (value === 'RESERVA_CARTAO') {
        return 'Reserva de Cartão'
      }
    },

    exibirDetalheProposta(data) {
      this.proposta = data
      this.$router.push({
        name: 'proposta-detalhe',
        params: { idproposta: data.id },
      })
    },
  },
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.pendente {
  color: #1f2d3d;
  background-color: #ffc107;
  font-weight: bold;
}
.ativa {
  color: #fff;
  background-color: #007bff;
  font-weight: bold;
}
.cancelada {
  color: #fff;
  background-color: #dc3545;
  font-weight: bold;
}
.suspensa {
  color: #1f2d3d;
  background-color: #c0c0c0;
  font-weight: bold;
}
.quitada {
  color: #fff;
  background-color: #28a745;
  font-weight: bold;
}
</style>
