export default class PropostaService {
  constructor(http) {
    this._http = http
  }

  async getListaPropostasConsignacaoDoServidor(id) {
    const { data } = await this._http.get(
      `/api/proposta-consignacao/matricula/${id}`,
    )
    return data
  }

  async getPropostasDaMatriculaEConsignatariaSelecionada(
    idMatricula,
    idConsignataria,
  ) {
    const { data } = await this._http.get(
      `/api/proposta-consignacao/matricula/${idMatricula}/consignataria/${idConsignataria}`,
    )
    return data
  }

  async getListaPropostasConsignatariaLogada(matriculaId) {
    const { data } = await this._http.get(
      `/api/proposta-consignacao/consignataria/matricula/${matriculaId}`,
    )
    return data
  }

  async getPropostaById(id) {
    const { data } = await this._http.get(`/api/proposta-consignacao/${id}`)
    return data
  }

  async savePropostaConsignacao(propostaConsignacao) {
    if (propostaConsignacao) {
      return await this._http.post(
        '/api/proposta-consignacao',
        propostaConsignacao,
      )
    }
  }

  async saveProposta(proposta) {
    if (proposta?.id) {
      return await this._http.put('/api/proposta-consignacao', proposta)
    } else {
      return await this._http.post('/api/proposta-consignacao', proposta)
    }
  }

  async aprovarProposta(proposta) {
    if (proposta) {
      return await this._http.patch(
        '/api/proposta-consignacao/aprovar',
        proposta,
      )
    }
  }

  async reprovarProposta(proposta) {
    if (proposta) {
      return await this._http.patch(
        '/api/proposta-consignacao/reprovar',
        proposta,
      )
    }
  }

  async cancelar(proposta) {
    if (proposta) {
      return await this._http.patch(
        '/api/proposta-consignacao/cancelar',
        proposta,
      )
    }
  }

  async criarPropostaReservaCartao(proposta) {
    console.log(proposta)
    if (proposta?.id) {
      return await this._http.put('/api/proposta-consignacao', proposta)
    } else {
      return await this._http.post(
        '/api/proposta-consignacao/reservaCartao',
        proposta,
      )
    }
  }

  async aprovarPropostaReservaCartao(proposta) {
    if (proposta) {
      return await this._http.patch(
        '/api/proposta-consignacao/aprovar/reservaCartao',
        proposta,
      )
    }
  }
}
